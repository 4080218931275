<template>
  <div>
    <el-form :model="company" ref="companyForm" label-width="80px" class="demo-ruleForm" :size="small">
      <el-row>
        <el-col :span="8">
          <el-form-item label="公司名称">
            <el-input v-model="company.companyName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="公司代码">
            <el-input v-model="company.companyCode"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="是否锁定">
            <el-select v-model="company.isLock" placeholder="是否锁定">
              <el-option label="全部" value=""></el-option>
              <el-option label="是" value="1"></el-option>
              <el-option label="否" value="0"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-button type="primary" icon="el-icon-search" :size="small" @click="findByPage">搜索</el-button>
        <!--        <el-button type="primary" :size="small">主要按钮</el-button>-->
        <el-button type="primary" icon="el-icon-plus" :size="small" @click="handleCreate">新增</el-button>
        <!--        <el-button type="info" :size="small">信息按钮</el-button>-->
        <!--        <el-button type="warning" :size="small">警告按钮</el-button>-->
        <!--        <el-button type="danger" :size="small">危险按钮</el-button>-->
      </el-row>
    </el-form>

    <el-table
        :data="tableData"
        :height="tableHeight"
        border
        :size="small"
        style="width: 100%;margin-top: 10px;"
        v-loading="loading"
        ref="topictable"
    >
      <el-table-column
          type="selection"
          width="55">
      </el-table-column>
      <el-table-column
          prop="id"
          label="id"
          width="120">
      </el-table-column>
      <el-table-column
          prop="companyName"
          label="公司名称"
          show-overflow-tooltip>
      </el-table-column>
      <el-table-column
          prop="companyCode"
          label="公司代码"
          show-overflow-tooltip>
      </el-table-column>
      <el-table-column
          prop="companyTaxCode"
          label="公司税号"
          show-overflow-tooltip>
      </el-table-column>
      <el-table-column
          prop="companyAddress"
          label="公司地址"
          show-overflow-tooltip>
      </el-table-column>
      <el-table-column
          prop="companyBank"
          label="公司银行"
          show-overflow-tooltip>
      </el-table-column>
      <el-table-column
          prop="createdTime"
          label="创建时间"
          show-overflow-tooltip>
      </el-table-column>
      <el-table-column
          prop="isLock"
          label="是否激活"
          :formatter="formatIsLock"
          show-overflow-tooltip>
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          width="200">
        <template slot-scope="{row}">
          <el-button type="text" :size="small" @click="handleUpdate(row)">编辑</el-button>
          <el-button type="text" :size="small" @click="handleDelete(row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageTotal"
        style="margin-top: 10px">
    </el-pagination>

    <el-dialog :title="formStatusMap[formStatus]" :visible.sync="dialogFormVisible" :center="true">
      <el-form ref="companyRef" :rules="rules" :model="companyForm" size="small">
        <el-form-item label="公司代码" prop="companyCode" :label-width="formLabelWidth">
          <el-input v-model="companyForm.companyCode" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="公司名称" prop="companyName" :label-width="formLabelWidth">
          <el-input v-model="companyForm.companyName" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="公司税号" prop="companyTaxCode" :label-width="formLabelWidth">
          <el-input v-model="companyForm.companyTaxCode" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="公司地址" :label-width="formLabelWidth">
          <el-input v-model="companyForm.companyAddress" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="公司银行" :label-width="formLabelWidth">
          <el-input v-model="companyForm.companyBank" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="是否激活" :label-width="formLabelWidth">
          <el-switch v-model="companyForm.isLock" :active-value="1" :inactive-value="0"></el-switch>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="resetCompanyForm" size="small">取 消</el-button>
        <el-button type="primary"
                   @click="formStatus==='create' ? companyFormSubmit('companyRef') : companyFormUpdate('companyRef')"
                   size="small">确 定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import request from '@/utils/request'

export default {
  data() {
    return {
      small: 'small',
      drawer: false,
      tableData: [],
      multipleSelection: [],
      currentPage: 1,
      pageSize: 10,
      loading: true,
      pageTotal: 0,
      tableHeight: null,
      company: {
        companyName: '',
        companyCode: '',
        isLock: '',
        deleted: 0
      },
      companyForm: {
        id: undefined,
        deleted: '',
        companyName: '',
        companyCode: '',
        isLock: '',
        companyTaxCode: '',
        companyAddress: '',
        companyBank: ''
      },
      rules: {
        companyName: [
          {required: true, message: '请填写公司名称', trigger: 'blur'}
        ],
        companyCode: [
          {required: true, message: '请填写公司代码', trigger: 'blur'}
        ],
        companyTaxCode: [
          {required: true, message: '请填写公司税号', trigger: 'blur'}
        ],
      },
      formStatus: '',
      formStatusMap: {
        create: '新增公司信息',
        update: '修改公司信息',
      },
      dialogFormVisible: false,
      formLabelWidth: '120px'
    }
  },

  methods: {
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.findByPage()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.findByPage()
    },
    onSubmit() {
      this.findByPage();
    },
    formatIsLock: function (row) {
      return row.isLock === 1 ? '是' : row.isLock === 0 ? '否' : '未知'
    },
    resetCompanyForm() {
      this.dialogFormVisible = false
      this.companyForm = {
        companyName: '',
        companyCode: '',
        isLock: '',
        companyTaxCode: '',
        companyAddress: '',
        companyBank: ''
      }
    },
    handleCreate() {
      this.dialogFormVisible = true
      this.formStatus = 'create'
      this.$nextTick(() => {
        this.$refs['companyRef'].clearValidate()
      })
    },
    handleUpdate(row) {
      this.companyForm = Object.assign({}, row)
      this.dialogFormVisible = true
      this.formStatus = "update"
      this.$nextTick(() => {
        this.$refs['companyRef'].clearValidate()
      })
    },
    handleDelete(row) {
      this.companyForm = Object.assign({}, row)
      this.companyForm.deleted = 1

      return request({
        method: 'post',
        url: '/masterdata/company/updateCompany',
        contentType: 'application/json',
        data: this.companyForm,
        baseURL: 'http://127.0.0.1:8093'
      }).then((response) => {
        if (response.data.id !== null) {
          this.$message({
            showClose: true,
            message: '删除成功',
            type: 'success',
          });

          this.findByPage()
        }
      })
    },
    companyFormSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          return request({
            method: 'post',
            url: '/masterdata/company/createCompany',
            contentType: 'application/json',
            data: this.companyForm,
            baseURL: 'http://127.0.0.1:8093'
          }).then((response) => {
            if (response.data.id !== null) {
              this.$message({
                showClose: true,
                message: '创建成功',
                type: 'success',
              });

              this.$refs[formName].resetFields()
              this.resetCompanyForm()
              this.dialogFormVisible = false
              this.findByPage()
            }
          })
        }
      });
    },
    companyFormUpdate(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          return request({
            method: 'post',
            url: '/masterdata/company/updateCompany',
            contentType: 'application/json',
            data: this.companyForm,
            baseURL: 'http://127.0.0.1:8093'
          }).then((response) => {
            if (response.data.id !== null) {
              this.$message({
                showClose: true,
                message: '修改成功',
                type: 'success',
              });

              this.$refs[formName].resetFields()
              this.resetCompanyForm()
              this.dialogFormVisible = false
              this.findByPage()
            }
          })
        }
      });
    },
    findByPage() {
      this.loading = true
      return request({
        url: `/masterdata/company/findByPage/${this.currentPage}/${this.pageSize}`,
        method: 'post',
        contentType: 'application/json',
        baseURL: 'http://127.0.0.1:8093',
        data: this.company
      }).then((response) => {
        this.tableData = response.data.content;
        this.pageTotal = response.data.totalElements;
        this.loading = false;
      })
    }
  }, mounted() {
    this.findByPage()
    this.tableHeight =
        window.innerHeight - this.$refs.topictable.$el.offsetTop - 80;
  },
}
</script>

<style>
.el-select {
  display: inline-block;
  position: relative;
  width: 100% !important;
}
</style>

